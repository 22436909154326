import React from 'react';
import NavControl from "../components/NavControl";
import {Auth} from "aws-amplify";
import {CognitoHostedUIIdentityProvider} from "@aws-amplify/auth";
import Container from "react-bootstrap/Container";
import {Outlet, NavLink} from 'react-router-dom'
import {useCognitoContext} from "../context/cognitoContext";
import {useLinkContext} from "../context/linkContext";
import {FcFilingCabinet, FcBusinesswoman, FcHome} from "react-icons/fc";

// admin page
const Admin = () => {
    const {cognitoGroups} = useCognitoContext()
    const {links} = useLinkContext()

    return (
        <Container className="container fluid">
            <div className='row flex-nowrap'>
                <div className='col-auto col-md-3 col-xl-2 px-sm-2 px-0'>
                    <div className='d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 min-vh-100'>
                        <NavControl title='Settings'
                                    onLogin={() => void Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Cognito})}
                                    onLogout={() => void Auth.signOut()}>
                            <NavLink
                                style={({isActive}) => {
                                    return {
                                        display: "block",
                                        margin: "1rem 0",
                                        color: isActive ? "purple" : "",
                                    };
                                }}
                                className='nav-item px-0' to='/'>
                                <i className='fs-4'><FcHome/></i><span className='ms-1 d-none d-sm-inline'>Home</span>
                            </NavLink>
                            {cognitoGroups.length > 0 ?
                                <NavLink
                                    style={({isActive}) => {
                                        return {
                                            display: "block",
                                            margin: "1rem 0",
                                            color: isActive ? "purple" : "",
                                        };
                                    }}
                                    className='nav-item px-0' to='groups'>
                                    <i className='fs-4'><FcBusinesswoman/></i><span className='ms-1 d-none d-sm-inline'>Edit Groups</span>
                                </NavLink> : <></>}
                            {links.length > 0 ?
                                <NavLink
                                    style={({isActive}) => {
                                        return {
                                            display: "block",
                                            margin: "1rem 0",
                                            color: isActive ? "purple" : "",
                                        };
                                    }}
                                    className='nav-item px-0' to='links'>
                                    <i className='fs-4'><FcFilingCabinet/></i><span className='ms-1 d-none d-sm-inline'>Edit Links</span>
                                </NavLink> : <></>}
                        </NavControl>

                    </div>
                </div>
                <div className='col py-3'>
                    {<Outlet context={{cognitoGroups, links, adminMode: true}}/>}
                </div>
            </div>

        </Container>

    );
}


export default Admin;
