import {ChangeEvent, ChangeEventHandler, FC, MouseEvent, useEffect, useState} from "react";
import {Modal, Button, Table} from "react-bootstrap";
import {UserType} from "@aws-sdk/client-cognito-identity-provider";
import {listUsers} from "../util/cognito/users";
import {Auth} from "aws-amplify";
import {groupMembers} from "../types/types";

interface props {
    cognitoGroup: groupMembers
    show: boolean;
    handleHide: ()=>void;
    handleClose: (e:MouseEvent<HTMLButtonElement>) => void;
    addUser: (e: MouseEvent<HTMLButtonElement>, user: UserType) => void;
    removeUser: (e: MouseEvent<HTMLButtonElement>, user: UserType) => void;
}

const GroupForm: FC<props> = ({cognitoGroup, show, handleClose, handleHide, addUser, removeUser}) => {
    const [cognitoUsers, setCognitoUsers] = useState<UserType[]>([])
    const [searchFieldText, setSearchFieldText] = useState('')
    const [originalCognitoUsers, setOriginalCognitoUsers] = useState<UserType[]>([])
    useEffect(() => {
        const getAllUsers = async () => {
            const cred = await Auth.currentCredentials()
            if (cred.authenticated) {
                const users = await listUsers({
                        UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID ?? 'us-west-2_8IZTM6dWC'
                    },
                    Auth.essentialCredentials(cred))
                if (users) {
                    if (users.Users) {
                        setCognitoUsers(users.Users)
                        setOriginalCognitoUsers(users.Users)
                    }
                }
            }
        }
        void getAllUsers()
    }, [])

    // return different button if user is already in the group or not
    const toggleUserButton = (user: UserType): JSX.Element => {
        if (cognitoGroup.groupMembers.find((member) => {
            return member.Username === user.Username
        })) {
            return <Button onClick={(e) => removeUser(e, user)}>Remove</Button>
        } else {
            return <Button onClick={(e) => addUser(e, user)}>Add</Button>
        }
    }

    const filter: ChangeEventHandler<HTMLInputElement> = (e: ChangeEvent<HTMLInputElement>) => {
        const keyword = e.target.value
        setSearchFieldText(keyword)
        if (keyword !== '') {
            const results = cognitoUsers.filter((user) => {
                const given_name = user.Attributes?.find((attr) => attr.Name === 'given_name')
                const family_name = user.Attributes?.find((attr) => attr.Name === 'family_name')
                if (given_name?.Value?.toLowerCase()?.includes(keyword)) return true
                if (family_name?.Value?.toLowerCase()?.includes(keyword)) return true
                // Not going to check "include" against username
                // as the username is generally a composite of first/lastname.
                return false
            })
            if (results?.length > 0) {
                setCognitoUsers(results)
                return
            } else {
                // no match, show 0
                setCognitoUsers([])
                return
            }
        }
        // reset search results with the original if the input filter is cleared.
        setCognitoUsers(originalCognitoUsers)
    }
    const closeForm = (e:MouseEvent<HTMLButtonElement>) => {
        setSearchFieldText('')
        setCognitoUsers(originalCognitoUsers)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        handleClose(e)
    }
    const hideForm = () => {
        setSearchFieldText('')
        setCognitoUsers(originalCognitoUsers)
        handleHide()
    }

    return (
        <Modal show={show} onHide={hideForm}>
            <Modal.Header closeButton>
                <Modal.Title>Select Users to Add</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <input
                    type="search"
                    value={searchFieldText}
                    onChange={filter}
                    className="input"
                    placeholder="Search Users"
                />
                <Table>
                    <thead>
                    <tr>
                        <th>User Name</th>
                        <th>Add/Remove</th>
                    </tr>
                    </thead>
                    <tbody>

                    {cognitoUsers && cognitoUsers.length > 0 ?
                        cognitoUsers.map((user, index) => {
                            return <tr key={index}>
                                <td className='lg'>{user.Username}</td>
                                <td className='lg'>{toggleUserButton(user)}</td>
                            </tr>
                        })
                        :
                        <tr>
                            <td colSpan={2}>No Matching Users in the user pool</td>
                        </tr>
                    }
                    </tbody>
                </Table>
                <Modal.Footer>
                    <Button variant="Primary" onClick={closeForm}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal.Body>

        </Modal>
    )
}

export default GroupForm
