import React, {FC, useState} from "react";
import Container from "react-bootstrap/Container";
import {reportType} from "../types/types";
import Chart, {GoogleChartWrapperChartType} from "react-google-charts";
import {Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";

type Props = {
    report: reportType;
}

export const Report: FC<Props> = ({report}) => {
    const [showReport, setShowReport] = useState(false)
    /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
    return (
        <>
            <div className="pb-3 mb-0 small lh-sm border-bottom w-100">
                <div className="d-flex justify-content-between">
                    <strong className="text-gray-dark">{report.title}</strong>
                    <Button onClick={() => {
                        setShowReport(true)
                    }
                    }>View</Button>
                </div>
                <span className="d-block">{report.description}</span>
            </div>
            <Modal show={showReport} fullscreen={true} onHide={() => setShowReport(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{report.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body><Container>
                    <Chart chartType={report.reportType as GoogleChartWrapperChartType}

                           data={ /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
                               JSON.parse(report.data)}
                           width="100%"
                           height="700px"
                           options={ /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
                               JSON.parse(report.options)}
                           legendToggle
                    />
                </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setShowReport(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )

}

export default Report
