import React from 'react';
import {NavLink} from "react-router-dom";
import {FcHome} from "react-icons/fc";

const NotFound = () => {
    return (
        <div className='container fluid'>
            <div className='row flex-nowrap'>
                <div className='col-auto col-md-3 col-xl-2 px-sm-2 px-0'>
                    <div
                        className='d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 min-vh-100'>
                        <div className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-decoration-none">

                            <img src='/asset/img/img.png' alt={"Not Found"}/>
                        </div>
                        <NavLink
                            style={() => {
                                return {
                                    display: "block",
                                    margin: "1rem 0",
                                };
                            }}
                            className='nav-item px-0'
                            to='/'>
                            <i className='fs-4'><FcHome/></i><span className='ms-1 d-none d-sm-inline'>Home</span>
                        </NavLink>
                    </div>
                </div>
                <div className='col py-3'>
                    <h1 style={{color: "red", fontSize: 100, textAlign: "center"}}>Not Found: 404</h1>
                    <h3 style={{fontSize: 50, textAlign: "center"}}>There is nothing here.</h3>
                </div>
            </div>
        </div>
    )
};

export default NotFound;
