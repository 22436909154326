import React, {useState, useContext, FC, useEffect, createContext, useCallback} from 'react';
import {Auth} from "aws-amplify";
import {useUserContext} from "./userContext";
import {deleteItem} from "../util/dynamodb/delete";
import {marshall} from "@aws-sdk/util-dynamodb";
import {scanTable} from "../util/dynamodb/scan";
import {putItem} from "../util/dynamodb/put";
import {linkType} from "../types/types";

const defaultState = {
    links: [],
}

interface ILinkContext {
    links: linkType[],
    removeLink?: (linkId: string) => void,
    addLink?: (link: linkType) => void,
    updateLink?: (newLink: linkType) => void
}

const LinkContext = createContext<ILinkContext>(defaultState);

export const useLinkContext = () => useContext(LinkContext);

export const LinkProvider: FC = ({children}): JSX.Element => {
    const {authenticated} = useUserContext()
    const [links, setLinks] = useState<linkType[]>([])

    const removeLink = (linkId: string) => {
        const tempLinks = links.filter((link) => link.id !== linkId)
        void removeLinkFromDynamo(linkId).then(() => setLinks(tempLinks))
    }

    const removeLinkFromDynamo = async (linkId: string) => {
        const cred = await Auth.currentCredentials()
        if (cred.authenticated) {
            await deleteItem({
                    TableName: process.env.REACT_APP_DYNAMODB_TABLE_NAME,
                    Key: marshall({"PK": "Link#" + linkId, "SK": "link"})
                },
                Auth.essentialCredentials(cred))
        }
    }

    const addLink = (link: linkType) => {
        void PutLinkToDynamo(link).then(() => setLinks([...links, link]))
    }

    const updateLink = (newLink: linkType) => {
        // replace the old link with the newly submitted link state.
        const tempLinks = links.map((link) => {
            if (link.id === newLink.id) {
                return newLink
            } else {
                return link
            }
        })
        // save the new link to DynamoDB table, and update the links state with the new links.
        void PutLinkToDynamo(newLink).then(() => setLinks(tempLinks))
    }

    const PutLinkToDynamo = async (link: linkType) => {
        const cred = await Auth.currentCredentials();
        if (cred.authenticated) {
            await putItem({
                    TableName: process.env.REACT_APP_DYNAMODB_TABLE_NAME,
                    Item: marshall(link)
                },
                Auth.essentialCredentials(cred)
            )
        }
    }

    const refreshLinks = useCallback(async () => {
        if (authenticated) {
            const cred = await Auth.currentCredentials()
            if (cred.authenticated) {
                void scanTable<linkType>({
                        TableName: process.env.REACT_APP_DYNAMODB_TABLE_NAME,
                    },
                    Auth.essentialCredentials(cred)
                ).then(linksFromServer => {
                    setLinks(linksFromServer)
                })
            }
        }
    }, [authenticated, setLinks])
    useEffect(() => {
        void refreshLinks()
    }, [refreshLinks])
    return (
        <LinkContext.Provider value={{links, removeLink, addLink, updateLink}}>
            {children}
        </LinkContext.Provider>
    );
}
