import {DynamoDBClient, ScanCommand, ScanCommandInput} from "@aws-sdk/client-dynamodb";
import {unmarshall} from '@aws-sdk/util-dynamodb'
import {ICredentials} from "aws-amplify/lib/Common/types/types";

// scanTable returns all data from the DynamoDB table by running a scan.
// this is a generics function, please make sure to specify type/interface for the return array type.
// if scan table fails, or there is no data, the function will return an empty array of the specified type.
export const scanTable = async<T> (scanInput:ScanCommandInput, credentials:ICredentials): Promise<T[]> => {

    const client = new DynamoDBClient({
        credentials: credentials,
        region: process.env.REACT_APP_AWS_REGION ?? 'us-west-2'
    });
    const command = new ScanCommand(scanInput);
    let response:T[] = []
    try {
        const results = await client.send(command)
        response = results.Items ? results.Items.map((item) => unmarshall(item) as T) : []
    } catch (err) {
        throw err;
    }
    return response
}

