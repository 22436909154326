/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ChangeEvent, FC, useState } from 'react'
import { useUserContext } from "../context/userContext";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ToolsDSCRForm from "./ToolsDSCRForm";

export const Tools: FC = () => {
  const [dscrShow, setDscrShow] = useState(false);
  const handleDscrShow = () => setDscrShow(true);
  const handleDscrClose = () => {
    setDscrShow(false)
  };
  const [dscrLoanNumber, setDscrLoanNumber] = useState("");
  const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLOptionElement | HTMLSelectElement>) => {
    setDscrLoanNumber(event.target.value);
  }

  return (
    <div>
      <div className="d-flex align-items-center p-3 my-3 text-white bg-danger rounded shadow-sm bg-gradient">
        <div className='pb-3 mb-0 lh-sm border-bottom w-100'>
          <div className="d-flex justify-content-between">
            <strong className="text-white">DSCR Eligibility Check</strong>
          </div>
        </div>
      </div>
      <div className="d-flex text-muted pt-3">
        <ToolsDSCRForm loanNumber={dscrLoanNumber} show={dscrShow} handleClose={handleDscrClose} handleHide={handleDscrClose} />
      </div>
    </div>
  )
}

export default Tools;
