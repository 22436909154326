import React from 'react'
import {Container} from "react-bootstrap";
import {Reports as ReportComponent} from '../components/Reports'


const Reports = () => {

    return (
        <Container>
            <ReportComponent/>
        </Container>
    )
}
export default Reports


