import Link from './Link'
import React, {FC, useState} from 'react'
import {linkType} from "../types/types";
import {useUserContext} from "../context/userContext";
import Button from "react-bootstrap/Button";
import LinkForm from "./LinkForm";
import {useLinkContext} from "../context/linkContext";
import {v4} from "uuid";
import {Loading} from "./Loading";

type props = {
    links: linkType[];
    adminMode: boolean;
};

export const Links: FC<props> = ({links, adminMode}) => {
    const {groups} = useUserContext()
    const [modalShow, setModalShow] = useState(false);
    const {addLink} = useLinkContext()
    const handleShow = () => setModalShow(true);
    const handleClose = () => {
        setModalShow(false)
    };
    const saveNewLink = (link: linkType) => {
        addLink?.(link)
        setModalShow(false)
    }
    const uuidV4 = v4().toString()
    const emptyLink = {
        PK: "Link#" + uuidV4,
        SK: "link",
        id: uuidV4,
        link: "",
        siteName: "",
        description: "",
        accessGroup: "admin", // default group
        displayPriority: 1
    }
    // filter out the links that user isn't allow to access, and return link JSX elements.
    return (
        <div>
            <div className="d-flex align-items-center p-3 my-3 text-white bg-danger rounded shadow-sm bg-gradient">
                <div className='pb-3 mb-0 lh-sm border-bottom w-100'>
                    <div className="d-flex justify-content-between">
                        {adminMode ?
                            <>
                                <strong className="text-white">Edit Links</strong>
                                <Button onClick={handleShow}>Create New Link</Button>
                            </>
                            :
                            <strong className="text-white">Links</strong>
                        }
                    </div>
                </div>
            </div>
            {links.length > 0 ?
                groups.map((group, groupIndex) => {
                    return (<div key={groupIndex}>
                            {links.filter((link) => link.accessGroup === group).length > 0 ?
                                <div className="my-3 p-3 bg-body rounded shadow-sm">
                                    <strong className="text-gray-dark">
                                        {group === process.env.REACT_APP_COGNITO_SAML_GROUP ? "OPERATIONS" : group.toUpperCase()}
                                    </strong>
                                    {links.filter((link) => link.accessGroup === group).map((link, index) => {
                                        return <Link key={index} link={link} adminMode={adminMode}/>
                                    })}
                                </div>
                                :
                                null
                            }
                        </div>
                    )
                })
                :
                <Loading/>
            }
            {/*new form*/}
            <LinkForm link={emptyLink} show={modalShow} handleClose={handleClose} handleHide={handleClose}
                      handleSave={saveNewLink}/>
        </div>
    )
}

export default Links
