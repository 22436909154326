import {FC, useState} from 'react'
import Button from 'react-bootstrap/Button';
import {useUserContext} from '../context/userContext';
import LinkForm from './LinkForm';
import {useLinkContext} from "../context/linkContext";
import {linkType} from "../types/types";

type props = {
    link: linkType;
    adminMode: boolean
};

const Link: FC<props> = ({link, adminMode}) => {
    const {groups} = useUserContext()
    const {updateLink, removeLink} = useLinkContext()
    const handleClick = (target: string) => {
        window.open(target)
    }
    const [modalShow, setModalShow] = useState(false);
    const handleShow = () => setModalShow(true);
    const handleClose = () => {
        setModalShow(false)
    };

    const saveLink = (link: linkType) => {
        updateLink?.(link)
        setModalShow(false)
    }
    const deleteLink = (link: linkType) => {
        removeLink?.(link.id)
        setModalShow(false)
    }
    return (
        <div className="d-flex text-muted pt-3">
            <div className="pb-3 mb-0 small lh-sm border-bottom w-100">
                <div className="d-flex justify-content-between">
                    <strong>{link.siteName}</strong>
                    <div>
                        <Button variant="primary" onClick={() => handleClick(link.link)}>Open</Button>
                                 {groups.includes('administrators') && adminMode
                                     ? <Button variant='secondary' onClick={handleShow}>Edit</Button>
                                    : null}
                    </div>
                </div>
                <span className="d-block">{link.description}</span>
            </div>
            <LinkForm link={link} show={modalShow} handleClose={handleClose} handleHide={handleClose}
                           handleSave={saveLink} handleDelete={deleteLink}/>
        </div>
    )
}

export default Link
