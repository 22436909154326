import { CognitoIdentityProviderClient, ListGroupsCommand,ListGroupsCommandInput,ListGroupsCommandOutput,
    AdminAddUserToGroupCommand, AdminAddUserToGroupCommandInput,AdminAddUserToGroupCommandOutput,
    AdminRemoveUserFromGroupCommand, AdminRemoveUserFromGroupCommandInput, AdminRemoveUserFromGroupCommandOutput,
    ListUsersInGroupCommand, ListUsersInGroupCommandInput, ListUsersInGroupCommandOutput} from "@aws-sdk/client-cognito-identity-provider";
import {ICredentials} from "aws-amplify/lib/Common/types/types";

// listsGroups lists all groups for a specified user pool id.
export const listGroups = async (listGroupInput:ListGroupsCommandInput, credentials:ICredentials):Promise<ListGroupsCommandOutput | null> => {
    const client = new CognitoIdentityProviderClient({
        region: process.env.REACT_APP_AWS_REGION ?? 'us-west-2',
        credentials: credentials
    });
    const command = new ListGroupsCommand(listGroupInput);
    let response:ListGroupsCommandOutput
    try {
        response = await client.send(command)
    } catch (err) {
        throw err;
    }
    return response
}

// addUserToGroup will add user to a specified group
export const addUserToGroup = async (adduserGroupInput: AdminAddUserToGroupCommandInput, credentials: ICredentials): Promise<AdminAddUserToGroupCommandOutput | null> => {
    const client = new CognitoIdentityProviderClient({
        region: process.env.REACT_APP_AWS_REGION ?? 'us-west-2',
        credentials: credentials
    });
    const command = new AdminAddUserToGroupCommand(adduserGroupInput);
    let response: AdminAddUserToGroupCommandOutput
    try {
        response = await client.send(command)
    } catch (err) {
        throw err;
    }
    return response
}

// removeUserFromGroup will remove user from the group.
export const removeUserFromGroup =
    async (removeUserGroupInput:AdminRemoveUserFromGroupCommandInput, credentials:ICredentials):Promise<AdminRemoveUserFromGroupCommandOutput | null> => {
    const client = new CognitoIdentityProviderClient({
        region: process.env.REACT_APP_AWS_REGION ?? 'us-west-2',
        credentials: credentials
    });
    const command = new AdminRemoveUserFromGroupCommand(removeUserGroupInput);
    let response:AdminRemoveUserFromGroupCommandOutput
    try {
        response = await client.send(command)
    } catch (err) {
        throw err;
    }
    return response
}

// listUsersInGroup returns all users for a specified group
export const listUsersInGroup = async (listUsersGroupInput:ListUsersInGroupCommandInput, credentials:ICredentials):Promise<ListUsersInGroupCommandOutput | null> => {
    const client = new CognitoIdentityProviderClient({
        region: process.env.REACT_APP_AWS_REGION ?? 'us-west-2',
        credentials: credentials
    });
    const command = new ListUsersInGroupCommand(listUsersGroupInput);
    let response:ListUsersInGroupCommandOutput
    try {
        response = await client.send(command)
    } catch (err) {
        throw err;
    }
    return response
}
