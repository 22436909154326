import {Group} from './Group'
import React from "react";
import Row from "react-bootstrap/Row";
import {useCognitoContext} from "../context/cognitoContext";
import Button from "react-bootstrap/Button";


export const Groups = () => {
    const {cognitoGroups} = useCognitoContext()
    // filter out built in SAML group (e.g. us-east-1_nkUiPGScq_GoogleWorkspace), as we must not modify the built in group.
    // return other groups as JSX elements
    return (
        <div>
            <div className="d-flex align-items-center p-3 my-3 text-white bg-danger rounded shadow-sm bg-gradient">
                <div className='pb-3 mb-0 lh-sm border-bottom w-100'>
                    <div className="d-flex justify-content-between">
                        <strong className="text-white">Edit Groups</strong>
                        <Button onClick={(e)=> e.preventDefault()}>Create New Group(Not working yet)</Button>
                    </div>
                </div>
            </div>
            <Row>
                {cognitoGroups.filter((cg) => (cg.group.GroupName !== (process.env.REACT_APP_COGNITO_SAML_GROUP ?? 'us-west-2_8IZTM6dWC_GoogleWorkspace')))
                    .map((group, index) => {
                        return <Group key={index} cognitoGroup={group}/>
                    })}
            </Row>
        </div>
    )

}
