import {DynamoDBClient, PutItemCommandInput, PutItemCommand, PutItemCommandOutput} from "@aws-sdk/client-dynamodb";
import {ICredentials} from "aws-amplify/lib/Common/types/types";

// putItem puts the input to the dynamoDB table.
export const putItem = async (putInput:PutItemCommandInput, credentials:ICredentials):Promise<PutItemCommandOutput | null> => {

    const client = new DynamoDBClient({
        credentials: credentials,
        region: process.env.REACT_APP_AWS_REGION ?? 'us-west-2'
    });
    const command = new PutItemCommand(putInput);
    let response:PutItemCommandOutput
    try {
        response = await client.send(command)
    } catch (err) {
        throw err;
    }
    return response
}
