import React, {FC} from 'react';
import {useOutletContext} from "react-router-dom";
import {Links as LinksComponent} from '../components/Links'
import {linkType} from "../types/types";
import Container from "react-bootstrap/Container";


const Links: FC = () => {
    const {links, adminMode} = useOutletContext<{ links: linkType[], adminMode: boolean }>()
    return (
        <Container>
            <LinksComponent links={links} adminMode={adminMode}/>
        </Container>
    )
};

export default Links;
