import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import {UserProvider} from "./context/userContext";
import AppRouter from './AppRouter';
import {CognitoProvider} from "./context/cognitoContext";
import {LinkProvider} from "./context/linkContext";

function App() {

    return (

        <BrowserRouter>
            <UserProvider>
                <CognitoProvider>
                    <LinkProvider>
                        <AppRouter/>
                    </LinkProvider>
                </CognitoProvider>
            </UserProvider>
        </BrowserRouter>

    );
}

export default App;
