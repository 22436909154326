import React from 'react';
import {Route, Routes} from 'react-router-dom';
import NotFound from './routes/NotFound';
import Main from './routes/Main';
import Admin from './routes/Admin'
import Groups from './routes/Groups'
import Links from "./routes/Links";
import Tools from "./routes/Tools";
import Reports from './routes/Reports'

function AppRouter() {
    return (
        <Routes>
            <Route path='/' element={<Main/>}>
                <Route path='report' element={
                    <React.Suspense fallback={<>...</>}>
                        <Reports/>
                    </React.Suspense>
                }/>
                <Route path='links' element={
                    <React.Suspense fallback={<>...</>}>
                        <Links/>
                    </React.Suspense>}/>
                <Route path='tools' element={
                    <React.Suspense fallback={<>...</>}>
                        <Tools/>
                    </React.Suspense>}/>
            </Route>
            <Route path='admin' element={
                <React.Suspense fallback={<>...</>}>
                    <Admin/>
                </React.Suspense>}>
                <Route path='groups' element={
                    <React.Suspense fallback={<>...</>}>
                        <Groups/>
                    </React.Suspense>}/>
                <Route path='links' element={
                    <React.Suspense fallback={<>...</>}>
                        <Links/>
                    </React.Suspense>}/>
            </Route>
            <Route path='*' element={<NotFound/>}/>
        </Routes>
    );
}

export default AppRouter;


