import React, {useState, useContext, FC, useEffect, createContext, Dispatch, SetStateAction, useCallback} from 'react';
import {Auth} from "aws-amplify";
import {UserType} from "@aws-sdk/client-cognito-identity-provider";
import {addUserToGroup, listGroups, listUsersInGroup, removeUserFromGroup} from "../util/cognito/groups";
import {useUserContext} from "./userContext";
import {groupMembers} from "../types/types";

const defaultState = {
    cognitoGroups: [],
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setCognitoGroups: () => {
    },
}

interface ICognitoContext {
    cognitoGroups: groupMembers[],
    setCognitoGroups: Dispatch<SetStateAction<groupMembers[]>>,
    removeGroupMember?: (userName: string, groupName: string) => Promise<void>,
    addGroupMember?: (user: UserType, groupName: string) => Promise<void>,
}

const CognitoContext = createContext<ICognitoContext>(defaultState);
export const useCognitoContext = () => useContext(CognitoContext);

export const CognitoProvider: FC = ({children}): JSX.Element => {
    const {authenticated, groups} = useUserContext()
    const [cognitoGroups, setCognitoGroups] = useState<groupMembers[]>([])

    const removeGroupMember = async (userName: string, groupName: string) => {
        const findGroup = cognitoGroups.find((cg) => cg.group.GroupName === groupName)
        // remove the specified user
        if (findGroup) {
            findGroup.groupMembers = findGroup.groupMembers.filter((gm) => gm.Username !== userName)

            // replace the existing group with the new one.
            const newCognitoGroups = cognitoGroups.map((cg): groupMembers => {
                if (cg.group.GroupName === groupName) {
                    return findGroup
                }
                return cg
            })
            await removeUserFromCognitoGroup(userName, groupName).then(() => setCognitoGroups(newCognitoGroups))
        }
    }

    const addGroupMember = async (user: UserType, groupName: string) => {
        const findGroup = cognitoGroups.find((cg) => cg.group.GroupName === groupName)
        if (findGroup) {
            // remove the specified user
            findGroup.groupMembers.push(user)
            // replace the existing group with the new one.
            const newCognitoGroups = cognitoGroups.map((cg): groupMembers => {
                if (cg.group.GroupName === groupName) {
                    return findGroup
                }
                return cg
            })
            if (user.Username) {
                await addUserToCognitoGroup(user.Username, groupName).then(() => setCognitoGroups(newCognitoGroups))
            }
        }
    }

    const removeUserFromCognitoGroup = async (userName: string, groupName: string) => {
        console.info("remove ", userName)
        const cred = await Auth.currentCredentials()
        if (cred.authenticated) {
            await removeUserFromGroup({
                    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID ?? 'us-west-2_8IZTM6dWC',
                    Username: userName,
                    GroupName: groupName
                },
                Auth.essentialCredentials(cred))
        }
    }

    const addUserToCognitoGroup = async (userName: string, groupName: string) => {
        console.info("add ", userName)
        const cred = await Auth.currentCredentials()
        if (cred.authenticated) {
            await addUserToGroup({
                    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID ?? 'us-west-2_8IZTM6dWC',
                    Username: userName,
                    GroupName: groupName
                },
                Auth.essentialCredentials(cred))
        }
    }

    const refreshGroups = useCallback(async () => {
        if (authenticated && groups.includes('administrators')) {
            const cred = await Auth.currentCredentials()
            if (cred.authenticated) {
                const cognitoGroups = await listGroups({
                        UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID ?? 'us-west-2_8IZTM6dWC',
                    },
                    Auth.essentialCredentials(cred))
                if (cognitoGroups?.Groups) {
                    const tempGroupMembers = await Promise.all(cognitoGroups.Groups.map<Promise<groupMembers>>(async (group) => {
                        const cognitoGroupMembers = await listUsersInGroup({
                                UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID ?? 'us-west-2_8IZTM6dWC',
                                GroupName: group.GroupName
                            },
                            Auth.essentialCredentials(cred))
                        if (cognitoGroupMembers?.Users) {
                            // console.log("number of users in the group:", cognitoGroupMembers?.Users)
                            return {
                                group: group,
                                groupMembers: cognitoGroupMembers.Users
                            }
                        } else {
                            return {
                                group: group,
                                groupMembers: []
                            }
                        }
                    }))
                    // console.log("temp group member check:", tempGroupMembers)

                    if (tempGroupMembers.length > 0) {
                        // console.log("temp group count is :", tempGroupMembers.length)
                        setCognitoGroups(tempGroupMembers)
                    }
                }
            }
        }
    }, [authenticated, groups])
    useEffect(() => {
        void refreshGroups()
    }, [refreshGroups])
    return (
        <CognitoContext.Provider value={{cognitoGroups, setCognitoGroups, removeGroupMember, addGroupMember}}>
            {children}
        </CognitoContext.Provider>
    );
}
