import React, {useState, useContext, FC, useEffect} from 'react';
import Amplify, {Auth, Hub} from "aws-amplify";
import {CognitoUser} from "@aws-amplify/auth";
import {CognitoUserAttribute} from "amazon-cognito-identity-js";

// sample env variable needed to operate Amplify Auth
// REACT_APP_COGNITO_APIURL=boinfra-development.auth.us-west-2.amazoncognito.com;
// REACT_APP_COGNITO_CLIENTID=3l2ube96840s7gubnonbeji6r5;
// REACT_APP_COGNITO_RESPONSE_TYPE=code;
// REACT_APP_COGNITO_SCOPE='openid','profile','email','aws.cognito.signin.user.admin';
// REACT_APP_COGNITO_REDIRECT_URL=http://localhost:1234;
// REACT_APP_COGNITO_URL_PREFIX=boinfra-development;
// REACT_APP_AWS_REGION=us-east-1;
// REACT_APP_COGNITO_USER_POOL_ID=us-east-1_nkUiPGScq;
// REACT_APP_COGNITO_SAML_GROUP=us-east-1_nkUiPGScq_GoogleWorkspace;
// REACT_APP_DYNAMODB_TABLE_NAME=backoffice_links
// REACT_APP_DYNAMODB_REPORT_TABLE_NAME=backoffice_report


Amplify.configure({
    Auth: {
        region: process.env.REACT_APP_AWS_REGION,
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENTID,
        identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,

        oauth: {
            domain: process.env.REACT_APP_COGNITO_APIURL,
            // need these scope in order to get users' attributes from the cognito user.
            // ['openid','profile','email','aws.cognito.signin.user.admin']
            // env variable will be split and converted to an array.
            scope: process.env.REACT_APP_COGNITO_SCOPE?.split(',') ?? ['openid','profile','email','aws.cognito.signin.user.admin'],
            redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_URL,
            redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_URL,
            responseType: 'code'
        }
    }
});

const defaultState = {
    user: null,
    groups: [],
    authenticated: false,
}

interface IUserContext {
    user: CognitoUser | null,
    groups: string[],
    authenticated: boolean,
    updateUser?: (userName: CognitoUser | null) => void,
    updateAuthenticatedStatus?: (status: boolean) => void,
    getGiven_Name?: () => string | undefined
}


const UserContext = React.createContext<IUserContext>(defaultState);
export const useUserContext = () => useContext(UserContext);

export const UserProvider: FC = ({children}): JSX.Element => {

    const [user, setUser] = useState<CognitoUser | null>(null);
    const [authenticated, setAuthenticated] = useState<boolean>(false)
    const [groups, setGroups] = useState<string[]>([])
    const [userAttributes, setUserAttributes] = useState<CognitoUserAttribute[]>([])
    const updateUser = (userName: CognitoUser | null) => {
        setUser(userName)
    }
    const updateAuthenticatedStatus = (status: boolean) => {
        setAuthenticated(status)
    }
    const getGiven_Name = (): string | undefined => {
        const name = userAttributes.find((attribute: CognitoUserAttribute) => attribute.Name === 'given_name')
        if (name) return name.Value
    }

    useEffect(() => {

        const unsubscribe = Hub.listen("auth", ({payload: {event, data}}) => {
            switch (event) {
                case "signIn":
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                    setUser(data);
                    break;
                case "signOut":
                    setUser(null);
                    break;
                // case "customOAuthState":
                //     setCustomState(data);
            }
        });
        return unsubscribe;
    }, [setUser]) // should run once when the page reloads

    useEffect(() => {
        const parseGroupFromSession = async (): Promise<string[]> => {
            try {
                const session = await Auth.currentSession()
                return session.getIdToken().payload['cognito:groups'] as string[] ;
            } catch (err) {
                console.info("parsing failed:", err)
            }
            return []
        }
        // on reload/refresh user will be null
        // perform Auth.currentAuthenticatedUser() to reinitialise user state
        // this will also renew the access_token using refresh token if it's expired.
        if (user === null) {
            try {
                Auth.currentAuthenticatedUser()
                    .then((currentUser: CognitoUser | null) => {
                        parseGroupFromSession().then(groups => {
                            setGroups(groups)
                            setUser(currentUser)
                            setAuthenticated(true);
                            // not all cognitoUser have an identical user attributes fields,
                            // some may have "name" some may have given_name, etc
                            // as a result, "CognitoUser" object does not export "attributes", would have been easier if it does use generics or something.
                            // so you have to use getUserAttributes() to extract the attributes object.
                            // For this project, given_name, family_name is obtained from SAML idp and stored in Cognito.
                            currentUser?.getUserAttributes((err: Error | undefined, attributes: CognitoUserAttribute[] | undefined) => {
                                if (attributes) {
                                    setUserAttributes(attributes)
                                }
                            })
                        }).catch((e) => {
                            console.info("error parsing group:", e)
                            setGroups([])
                            setUser(null)
                            setAuthenticated(false);
                        })
                    })
                    .catch(() => console.info("Not signed in"));
            } catch (e) {
                console.info("checking for current authenticated user failure: ", e)
            }
        }
    }, [user, userAttributes])
    return (
        <UserContext.Provider
            value={{user, getGiven_Name, groups, authenticated, updateUser, updateAuthenticatedStatus}}>
            {children}
        </UserContext.Provider>
    );
}
