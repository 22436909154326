import React from 'react';
import {Groups as GroupComponent} from '../components/Groups'
import Container from "react-bootstrap/Container";


const Groups = () => {
    return (
        <Container>
            <GroupComponent/>
        </Container>
    )
};

export default Groups;
