import {
    ListUsersCommand,
    ListUsersCommandInput,
    ListUsersCommandOutput, CognitoIdentityProviderClient
} from "@aws-sdk/client-cognito-identity-provider";
import {ICredentials} from "aws-amplify/lib/Common/types/types";

// listUsers return all users from the specified user pool.
export const listUsers = async (listUsersInput: ListUsersCommandInput, credentials: ICredentials): Promise<ListUsersCommandOutput | null> => {
    const client = new CognitoIdentityProviderClient({
        region: process.env.REACT_APP_AWS_REGION ?? 'us-west-2',
        credentials: credentials
    });
    const command = new ListUsersCommand(listUsersInput);
    let response: ListUsersCommandOutput
    try {
        response = await client.send(command)
    } catch (err) {
        throw err;
    }
    return response
}
