/* eslint-disable max-len, no-console, @typescript-eslint/no-unsafe-return */
import fetch from 'node-fetch';
// import { getSecret } from '../secretsmanager/get';

export const calc = async (payload: string) => {
  const dscrUrl = process.env.REACT_APP_DSCRENGINE_URL ?? 'https://engine.dscr.service.makeabeeline.dev/engine/preapproval/dscr';
  
  return await fetch(
    dscrUrl,
    {
      method: 'post',
      headers: {
        'Content-Type': 'text/xml'
      },
      body: payload
    }
  )
    .then(response => response.json())
    .then(json => {
      return json;
    })
    .catch(error => {
      // Sentry.captureException(error);
      console.error('DSCR Error:', error);
      return null;
    });
};
