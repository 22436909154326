import React, {useEffect, useState} from 'react'
import {Auth} from "aws-amplify"
import {useUserContext} from "../context/userContext"
import {scanTable} from "../util/dynamodb/scan";
import {reportType} from "../types/types";
import Report from './Report'
import {Loading} from "./Loading";

export const Reports = () => {
    const {authenticated} = useUserContext()
    const [results, setResults] = useState<reportType[]>([])

    useEffect(() => {
        const listsReports = async () => {
            if (authenticated) {
                const cred = await Auth.currentCredentials()
                if (cred.authenticated) {
                    await scanTable<reportType>({
                            TableName: process.env.REACT_APP_DYNAMODB_REPORT_TABLE_NAME,
                        },
                        Auth.essentialCredentials(cred)
                    ).then(results => {
                        setResults(results)
                    })
                }
            }
        }
        void listsReports()
    }, [setResults, authenticated])


    return (
        <div>
            <div className="d-flex align-items-center p-3 my-3 text-white bg-danger rounded shadow-sm bg-gradient">
                <div className='pb-3 mb-0 lh-sm border-bottom w-100'>
                    <div className="d-flex justify-content-between">
                        <strong className="text-white">Reports</strong>
                    </div>
                </div>
            </div>
            <div className="my-3 p-3 bg-body rounded shadow-sm">
                <h6 className="border-bottom pb-2 mb-0">Loan flow</h6>
                {results.length > 0 ?
                    results.sort((a, b) => 0 - (a.GS1SK > b.GS1SK ? 1 : -1))
                        .map((result, index) => {
                        return (<div className="d-flex text-muted pt-3" key={index}>
                            <Report key={index} report={result}/>
                        </div>)
                    })
                    :
                    <Loading/>
                }

            </div>
        </div>

    )
}


