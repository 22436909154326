import {
    DynamoDBClient,
    DeleteItemCommandInput, DeleteItemCommandOutput, DeleteItemCommand
} from "@aws-sdk/client-dynamodb";
import {ICredentials} from "aws-amplify/lib/Common/types/types";

// deleteItem delete the input from the dynamoDB table.
export const deleteItem = async (deleteInput: DeleteItemCommandInput, credentials: ICredentials): Promise<DeleteItemCommandOutput | null> => {

    const client = new DynamoDBClient({
        credentials: credentials,
        region: process.env.REACT_APP_AWS_REGION ?? 'us-west-2'
    });
    const command = new DeleteItemCommand(deleteInput);
    let response: DeleteItemCommandOutput
    try {
        response = await client.send(command)
    } catch (err) {
        throw err;
    }
    return response
}
