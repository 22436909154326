/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {FC} from 'react';
import {useOutletContext} from "react-router-dom";
import {Tools as ToolsComponent} from '../components/Tools'
import Container from "react-bootstrap/Container";

const Tools: FC = () => {
    return (
        <Container>
            <ToolsComponent />
        </Container>
    )
};

export default Tools;
