/* eslint-disable max-len, no-console, @typescript-eslint/no-unused-vars, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-misused-promises  */
import { ChangeEvent, FC, MouseEventHandler, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Table from 'react-bootstrap/Table';
import Tooltip from 'react-bootstrap/Tooltip';
import { useUserContext } from '../context/userContext'
import { FcInfo } from 'react-icons/fc';
import { Loading } from './Loading';
import { calc } from '../util/dscr/calc';
import { NumberAttributeConstraintsType } from '@aws-sdk/client-cognito-identity-provider';

interface props {
  loanNumber: string;
  show: boolean;
  handleHide: () => void;
  handleClose: MouseEventHandler<HTMLButtonElement | HTMLAreaElement>;
}

const ToolsDSCRForm: FC<props> = ({ loanNumber, show, handleClose, handleHide }) => {
  const [state, setState] = useState<{ [x: string]: (string | boolean | number) }>({
    // all fields DSCR calc needs
    referenceNumber: '',
    anyBorrowerIsForeigner: false,
    anyBorrowerIsLLC: false,
    propertyType: 'SFR',
    isRural: false,
    propertyAddressState: 'TX',
    unitsNumber: 1,
    loanPurpose: 'Purchase',
    creditScore: 680,
    isFirstTimeInvestor: false,
    hasGifts: false,
    estimatedPropertyValue: 500000,
    proposedLoanAmount: 300000,
    monthlyRentalIncome: 3000,
    isMixedUseProperty: false,
    mixedUseCommercialCount: 0,
    isPartiallyVacant: false,
    vacantUnitsNumber: 0,
    monthlySubjectPropertyExpense_Taxes: 100,
    monthlySubjectPropertyExpense_Insurance: 100,
    monthlySubjectPropertyExpense_CommonCharges: 0,
    monthlySubjectPropertyExpense_GroundRents: 0,
    interestRate: 5.3,
    loanTermInYear: 30,
    interestOnlyloanTermInYear: 0,
    ARMInYear: 5,
  });
  const [loanLoaded, setLoanLoaded] = useState(true);
  // const [loan, setLoan] = useState({});
  const [plans, setPlans] = useState({ message: '' });
  const [invalidPlans, setInvalidPlans] = useState({});

  const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLOptionElement | HTMLSelectElement>) => {
    const target = event.target;
    const boolFields = ['anyBorrowerIsForeigner', 'anyBorrowerIsLLC', 'isRural', 'isFirstTimeInvestor', 'hasGifts', 'isMixedUseProperty', 'isPartiallyVacant'];
    let value: (string | boolean) = target.value;
    if (boolFields.includes(target.id)) {
      const oldBoolValue = (Boolean)(state[target.id]);
      value = !oldBoolValue;
    }

    setState({
      ...state,
      [target.id]: value
    });
  }

  const dscrCalc = async () => {
    setPlans({ message: '' });
    const reqBody = {
      // all fields DSCR calc needs
      referenceNumber: 'backoffice',
      borrowers: [
        {
          isForeigner: state.anyBorrowerIsForeigner,
        },
      ],
      propertyUnderLLC: state.anyBorrowerIsLLC,
      propertyType: state.propertyType,
      // isRural: state.isRural,
      propertyAddressState: state.propertyAddressState,
      unitsNumber: state.unitsNumber,
      loanPurpose: state.loanPurpose,
      creditScore: state.creditScore,
      isFirstTimeInvestor: state.isFirstTimeInvestor,
      hasGifts: state.hasGifts,
      estimatedPropertyValue: state.estimatedPropertyValue,
      proposedLoanAmount: state.proposedLoanAmount,
      monthlyRentalIncome: state.monthlyRentalIncome,
      isMixedUseProperty: state.isMixedUseProperty,
      isPartiallyVacant: state.isPartiallyVacant,
      monthlySubjectPropertyExpense_Taxes: state.monthlySubjectPropertyExpense_Taxes,
      monthlySubjectPropertyExpense_Insurance: state.monthlySubjectPropertyExpense_Insurance,
      monthlySubjectPropertyExpense_CommonCharges: state.monthlySubjectPropertyExpense_CommonCharges,
      monthlySubjectPropertyExpense_GroundRents: state.monthlySubjectPropertyExpense_GroundRents,
      interestRate: Number(state.interestRate) / 100,
      loanTermInYear: state.loanTermInYear,
      interestOnlyloanTermInYear: state.interestOnlyloanTermInYear,
      ARMInYear: state.ARMInYear,
    };
    // if (state.isMixedUseProperty) {
    //   reqBody.mixedUseCommercialCount = state.mixedUseCommercialCount;
    // }
    const respBody = await calc(JSON.stringify(reqBody));
    setPlans(respBody && respBody.status === 'SUCCESS' ? respBody.plans : { message: respBody.message });
    setInvalidPlans(respBody.invalid_plans);
  };

  const { authenticated } = useUserContext();
  useEffect(() => {
    const lqbLoadLoan = async () => {
      if (authenticated && loanNumber !== undefined) {
        const cred = await Auth.currentCredentials();
        if (cred.authenticated) {
          // await lqbAuthenticate()
          //   .then(token => {
          //     return loadLoan(token, loanNumber);
          //   })
          //   .then(() => {
          //     // setLoan(loan);
          //     setLoanLoaded(true);
          //   });
        }
      }
    };
    void lqbLoadLoan();
  }, [setLoanLoaded, authenticated])

  return (
    <Form>
      <Form.Group className="mb-3">
        <Form.Label>Will the loan close in the name of a business entity (Y/N)?</Form.Label>
        <Form.Check type="checkbox" label="Yes" id="anyBorrowerIsLLC" onChange={handleInputChange} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Is there a foreign borrower (Y/N)?</Form.Label>
        <Form.Check type="checkbox" label="Yes" id="anyBorrowerIsForeigner" onChange={handleInputChange} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Is the borrower a first time investor (Y/N)?</Form.Label>
        <Form.Check type="checkbox" label="Yes" id="isFirstTimeInvestor" onChange={handleInputChange} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Is the borrower using a gift (Y/N)?</Form.Label>
        <Form.Check type="checkbox" label="Yes" id="hasGifts" onChange={handleInputChange} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Is Property Mixed Use (Y/N)?</Form.Label>
        <Form.Check type="checkbox" label="Yes" id="isMixedUseProperty" onChange={handleInputChange} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>If Yes, how many units are commercial?</Form.Label>
        <Form.Control id="mixedUseCommercialCount" type="text"
          onChange={handleInputChange} defaultValue={Number(state.mixedUseCommercialCount)} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Are any units vacant (Y/N)?</Form.Label>
        <Form.Check type="checkbox" label="Yes" id="isPartiallyVacant" onChange={handleInputChange} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>If Yes, how many units are vacant?</Form.Label>
        <Form.Control id="vacantUnitsNumber" type="text"
          onChange={handleInputChange} defaultValue={Number(state.vacantUnitsNumber)} />
      </Form.Group>
      <strong className="bg-primary text-white">PROPERTY BASIC INFO:</strong>
      <Form.Group className="mb-3">
        <Form.Label>Is the property Rural (Y/N)?</Form.Label>
        <Form.Check type="checkbox" label="Yes" id="isRural" onChange={handleInputChange} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Property Type</Form.Label>
        <Form.Select id="propertyType" aria-label="propertyType" onChange={handleInputChange} defaultValue={String(state.propertyType)}>
          <option value="SFR">SFR</option>
          <option value="2 Units">2 Units</option>
          <option value="3 Units">3 Units</option>
          <option value="4 Units">4 Units</option>
          <option value="Condo">Condo</option>
          <option value="PUD">PUD</option>
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Property State</Form.Label>
        <Form.Control id="propertyAddressState" type="text"
          onChange={handleInputChange} defaultValue={String(state.propertyAddressState)} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label># of Units</Form.Label>
        <Form.Control id="unitsNumber" type="text"
          onChange={handleInputChange} defaultValue={Number(state.unitsNumber)} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Loan Purpose</Form.Label>
        <Form.Select id="loanPurpose" aria-label="loanPurpose" onChange={handleInputChange} defaultValue={String(state.loanPurpose)}>
          <option value="Purchase">Purchase</option>
          <option value="Payment Refi">Rate/Term refinance</option>
          <option value="Cash-out Refi">Cash-out Refinance</option>
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>FICO</Form.Label>
        <Form.Control id="creditScore" type="text"
          onChange={handleInputChange} defaultValue={Number(state.creditScore)} />
      </Form.Group>
      <strong className="bg-primary text-white">DSC RATIO INFO:</strong>
      <Form.Group className="mb-3">
        <Form.Label>Estimated Property Value</Form.Label>
        <InputGroup className="mb-3">
          <InputGroup.Text>$</InputGroup.Text>
          <Form.Control id="estimatedPropertyValue" type="text"
            onChange={handleInputChange} defaultValue={Number(state.estimatedPropertyValue)} />
        </InputGroup>
      </Form.Group>
      <Form.Group className="mb-3">
        <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-proposedLoanAmount-label">≥ $100K</Tooltip>}>
          <Form.Label>Loan Amount</Form.Label>
        </OverlayTrigger>
        <InputGroup className="mb-3">
          <InputGroup.Text>$</InputGroup.Text>
          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-proposedLoanAmount-input">≥ $100K</Tooltip>}>
            <Form.Control id="proposedLoanAmount" type="text"
              onChange={handleInputChange} defaultValue={Number(state.proposedLoanAmount)} />
          </OverlayTrigger>
        </InputGroup>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Gross Monthly Rent</Form.Label>
        <InputGroup className="mb-3">
          <InputGroup.Text>$</InputGroup.Text>
          <Form.Control id="monthlyRentalIncome" type="text"
            onChange={handleInputChange} defaultValue={Number(state.monthlyRentalIncome)} />
        </InputGroup>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Monthly Property Taxes</Form.Label>
        <InputGroup className="mb-3">
          <InputGroup.Text>$</InputGroup.Text>
          <Form.Control id="monthlySubjectPropertyExpense_Taxes" type="text"
            onChange={handleInputChange} defaultValue={Number(state.monthlySubjectPropertyExpense_Taxes)} />
        </InputGroup>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Monthly Insurance Cost</Form.Label>
        <InputGroup className="mb-3">
          <InputGroup.Text>$</InputGroup.Text>
          <Form.Control id="monthlySubjectPropertyExpense_Insurance" type="text"
            onChange={handleInputChange} defaultValue={Number(state.monthlySubjectPropertyExpense_Insurance)} />
        </InputGroup>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Month Common Charges/HOA, if any</Form.Label>
        <InputGroup className="mb-3">
          <InputGroup.Text>$</InputGroup.Text>
          <Form.Control id="monthlySubjectPropertyExpense_CommonCharges" type="text"
            onChange={handleInputChange} defaultValue={Number(state.monthlySubjectPropertyExpense_CommonCharges)} />
        </InputGroup>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Monthy Ground Rents, if any
          <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-monthlySubjectPropertyExpense_GroundRents-tip">to get the definition of ground rent: ground rent means that the building/structures are owned by someone who does not also own the land that the building/structures sit on.  This is most common in Maryland, Pennsylvania and Virginia.  Make sure you know if ground rent is involved in your transactions.</Tooltip>}>
            <i className='fs-4'><FcInfo /></i>
          </OverlayTrigger>
        </Form.Label>
        <InputGroup className="mb-3">
          <InputGroup.Text>$</InputGroup.Text>
          <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-monthlySubjectPropertyExpense_GroundRents-input">to get the definition of ground rent: ground rent means that the building/structures are owned by someone who does not also own the land that the building/structures sit on.  This is most common in Maryland, Pennsylvania and Virginia.  Make sure you know if ground rent is involved in your transactions.</Tooltip>}>
            <Form.Control id="monthlySubjectPropertyExpense_GroundRents" type="text"
              onChange={handleInputChange} defaultValue={Number(state.monthlySubjectPropertyExpense_GroundRents)} />
          </OverlayTrigger>
        </InputGroup>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Estimated Interest Rate</Form.Label>
        <InputGroup className="mb-3">
          <Form.Control id="interestRate" type="text"
            onChange={handleInputChange} defaultValue={Number(state.interestRate)} />
          <InputGroup.Text>%</InputGroup.Text>
        </InputGroup>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Loan Term (in Years)</Form.Label>
        <Form.Control id="loanTermInYear" type="text"
          onChange={handleInputChange} defaultValue={Number(state.loanTermInYear)} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Interest only Period (in Years) if applicable</Form.Label>
        <Form.Control id="interestOnlyloanTermInYear" type="text"
          onChange={handleInputChange} defaultValue={Number(state.interestOnlyloanTermInYear)} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>ARM period (in Years)</Form.Label>
        <Form.Control id="ARMInYear" type="text"
          onChange={handleInputChange} defaultValue={Number(state.ARMInYear)} />
      </Form.Group>
      <Button className="mb-3" variant="primary" onClick={dscrCalc}>Check</Button>
      {(plans && Array.isArray(plans) && plans.length > 0) && (
        <Table striped bordered hover responsive>
          <tbody>
            <tr>
              <td>Investor</td>
              {plans.map((plan, index) => (
                <td key={`provider-${index}`}>{plan.provider}</td>
              ))}
            </tr>
            <tr>
              <td>Program</td>
              {plans.map((plan, index) => (
                <td key={`product-${index}`}>{plan.product}</td>
              ))}
            </tr>
            <tr>
              <td>Interest rate</td>
              {plans.map((plan, index) => (
                <td key={`interestRate-${index}`}>{plan.interestRate * 100}%</td>
              ))}
            </tr>
            <tr>
              <td>DSCR</td>
              {plans.map((plan, index) => (
                <td key={`DSCRatio-${index}`}>{plan.DSCRatio}</td>
              ))}
            </tr>
            <tr>
              <td>Reserve requirement</td>
              {plans.map((plan, index) => (
                <td key={`reservesAmount-${index}`}>${plan.reservesAmount}</td>
              ))}
            </tr>
            <tr>
              <td>LTV</td>
              {plans.map((plan, index) => (
                <td key={`LTV-${index}`}>{plan.LTV * 100}%</td>
              ))}
            </tr>
          </tbody>
        </Table>
      )}
      {(plans && Array.isArray(plans) && plans.length === 0 && Array.isArray(invalidPlans) && invalidPlans.length > 0) && (
        <Table striped bordered hover responsive>
          <tbody>
            {invalidPlans.map((plan, index) => (
              <tr><td key={`msg-${index}`}>{plan.msg}</td></tr>
            ))}
          </tbody>
        </Table>
      )}
      {(!Array.isArray(plans)) && (
        <Form.Group className="mb-3">
          <Form.Text id="resp">{plans.message}</Form.Text>
        </Form.Group>
      )}
    </Form>
  )
}

export default ToolsDSCRForm;
