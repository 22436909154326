import React, {useState} from 'react';
import NavControl from '../components/NavControl'
import '../css/Main.css'
import {useUserContext} from "../context/userContext";
import {Auth} from "aws-amplify";
import {CognitoHostedUIIdentityProvider} from "@aws-amplify/auth";

import {useLinkContext} from "../context/linkContext";
import Container from "react-bootstrap/Container";
import {NavLink, Outlet, useLocation} from "react-router-dom";
import {FcAreaChart, FcBookmark, FcHome, FcCalculator} from "react-icons/fc";

const Main = () => {
    const {user, getGiven_Name} = useUserContext();
    const {links} = useLinkContext()
    // hide main page content if the url isn't '/'
    const [showMain, setShowMain] = useState(useLocation().pathname === '/')

    return (
        <Container className='container fluid'>
            <div className='row flex-nowrap'>
                {user ? <div className='col-auto col-md-3 col-xl-2 px-sm-2 px-0'>
                    <div className='d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 min-vh-100'>
                        <NavControl title='Beeline Back Office Services'
                                    onLogin={() => void Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Cognito})}
                                    onLogout={() => void Auth.signOut()}>
                            <NavLink
                                style={({isActive}) => {
                                    return {
                                        display: "block",
                                        margin: "1rem 0",
                                        color: isActive ? "purple" : "",
                                    };
                                }}
                                className='nav-item px-0'
                                onClick={() => setShowMain(true)}
                                to='/'>
                                <i className='fs-4'><FcHome/></i><span className='ms-1 d-none d-sm-inline'>Home</span>
                            </NavLink>
                            {user ?
                                <NavLink
                                    style={({isActive}) => {
                                        return {
                                            display: "block",
                                            margin: "1rem 0",
                                            color: isActive ? "purple" : "",
                                        };
                                    }}
                                    className='nav-item px-0'
                                    onClick={() => setShowMain(false)}
                                    to='links'>
                                    <i className='fs-4'><FcBookmark/></i>
                                    <span className='ms-1 d-none d-sm-inline'>Links</span>
                                </NavLink> : <></>}
                            {user ?
                                <NavLink
                                    style={({isActive}) => {
                                        return {
                                            display: "block",
                                            margin: "1rem 0",
                                            color: isActive ? "purple" : "",
                                        };
                                    }}
                                    className='nav-item px-0'
                                    onClick={() => setShowMain(false)}
                                    to='report'>
                                    <i className='fs-4'><FcAreaChart/></i>
                                    <span className='ms-1 d-none d-sm-inline'>Report</span>
                                </NavLink> : <></>}
                            {user ?
                                <NavLink
                                    style={({isActive}) => {
                                        return {
                                            display: "block",
                                            margin: "1rem 0",
                                            color: isActive ? "purple" : "",
                                        };
                                    }}
                                    className='nav-item px-0'
                                    onClick={() => setShowMain(false)}
                                    to='tools'>
                                    <i className='fs-4'><FcCalculator/></i>
                                    <span className='ms-1 d-none d-sm-inline'>Tools</span>
                                </NavLink> : <></>}
                        </NavControl>
                    </div>
                </div>
                :
                    null
                }
                <div className='col py-3'>
                    {showMain ?
                        user ?
                            <div className="login"><span className='flex column'>Hi {getGiven_Name?.()}! Welcome to the backoffice</span>
                            </div>
                            : <div className="login">
                                <img src="/asset/img/beeline-logo.png" alt="Beeline Loans"
                                     style={{alignItems: "center", paddingBottom: "20px"}}
                                     className='ms-1 d-none d-sm-inline'/>
                                <h3 style={{textAlign: "center", paddingBottom: "20px"}}>Welcome to Beeline Loan</h3>
                                <div className="d-grid gap-2 col-6 mx-auto">
                                    <button className="btn btn-outline-primary"
                                            onClick={() => {
                                                void Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Cognito})
                                            }}>
                                        Log in
                                    </button>
                                </div>
                            </div>
                        : null
                    }
                    <Outlet context={{links, adminMode: false}}/>
                </div>
            </div>
        </Container>
    );
}


export default Main;
