/* eslint-disable no-undef */
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/index.css'
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
let root;
if (container) {
    root = createRoot(container);
    root.render(<App />);
}
