import React, {FC, MouseEventHandler} from 'react'
import {useUserContext} from "../context/userContext";
import {NavLink} from "react-router-dom";
import {FcEngineering} from "react-icons/fc";
import {FaPowerOff} from "react-icons/fa";

type props = {
    title: string;
    onLogin: MouseEventHandler<HTMLButtonElement>;
    onLogout: MouseEventHandler<HTMLButtonElement>;
    children?: JSX.Element|JSX.Element[];
}

const NavControl: FC<props> = ({title, onLogin, onLogout, children}) => {
    const {user, groups} = useUserContext();

    return (
        <div>
            <div className="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-decoration-none">

                <img src='/asset/img/beeline-logo.png' alt={title} className='ms-1 d-none d-sm-inline'/>
            </div>
            <ul className="nav flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
                {children}
                {user ?
                    groups.includes('administrators') ?
                        <NavLink
                            style={({isActive}) => {
                                return {
                                    display: "block",
                                    margin: "1rem 0",
                                    color: isActive ? "purple" : "",
                                };
                            }}
                            className='nav-item px-0' to='/admin'>
                            <i className='fs-4'><FcEngineering/></i><span
                            className='ms-1 d-none d-sm-inline'>Settings</span>
                        </NavLink>
                        :
                        null
                    : null
                }

                {user ?
                    <div className="nav-item px-0" style={{display: "block", margin: "1rem 0"}}>
                        <i className='fs-4' onClick={onLogout}><FaPowerOff/></i>
                        <span className="ms-1 d-none d-sm-inline" onClick={onLogout}>Log out</span>
                    </div>
                    :
                    <div className="nav-item px-0" style={{display: "block", margin: "1rem 0"}}>
                        <i className='fs-4' onClick={onLogin}><FaPowerOff/></i>
                        <span className="ms-1 d-none d-sm-inline" onClick={onLogin}>Login</span>
                    </div>
                }
            </ul>
        </div>
    )
}


export default NavControl
