import React, {FC, MouseEvent, useState} from 'react'
import {
    UserType
} from '@aws-sdk/client-cognito-identity-provider'
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import GroupForm from "./GroupForm";

import {useCognitoContext} from "../context/cognitoContext";
import {groupMembers} from "../types/types";


type props = {
    cognitoGroup: groupMembers;
};

export const Group: FC<props> = ({cognitoGroup}) => {
    const {removeGroupMember, addGroupMember} = useCognitoContext()

    const removeUser = (e:MouseEvent<HTMLButtonElement>, user: UserType) => {
        e.preventDefault()
        if (user.Username && cognitoGroup.group.GroupName) {
            void removeGroupMember?.(user.Username, cognitoGroup.group.GroupName)
        }
        handleClose(e)
    }

    const addUser = (e: MouseEvent<HTMLButtonElement>, user: UserType) => {
        e.preventDefault()
        if (cognitoGroup.group.GroupName) {
            void addGroupMember?.(user, cognitoGroup.group.GroupName)
        }
        handleClose(e)
    }

    const [modalShow, setModalShow] = useState(false);
    const handleShow = () => setModalShow(true);
    const handleClose = (e:MouseEvent<HTMLButtonElement>) => {
        console.info(e)
        setModalShow(false)
    }
    const handleHide = () => {
        setModalShow(false)
    }

    return (
        <Table bordered>
            <thead>
            <tr>
                <th className='lg'>Group Name</th>
                <th className='lg'>Group Description</th>
                <th className='lg'>Group IAM Role ARN</th>
                <th className='lg'>Edit Members</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td className='lg'>{cognitoGroup.group.GroupName}</td>
                <td className='lg'>{cognitoGroup.group.Description ?? 'N/A'}</td>
                <td className='lg'>
                    {cognitoGroup.group.RoleArn ?
                        <a href={'https://us-west-2.console.aws.amazon.com/iamv2/home?region=us-west-2#/roles/details/' + cognitoGroup.group.RoleArn.split('/')[1] + '?section=permissions'}>{cognitoGroup.group.RoleArn}</a>
                        :
                        'N/A'}
                </td>
                <td className='lg'>
                    <Button onClick={handleShow}>Edit</Button>
                    <GroupForm cognitoGroup={cognitoGroup} show={modalShow} handleHide={handleHide}
                               handleClose={handleClose} addUser={addUser} removeUser={removeUser}/>
                </td>
            </tr>
            <tr>
                <td colSpan={4}>
                    <Table hover bordered>
                        <thead>
                        <tr>
                            <th className='lg'>#</th>
                            <th className='lg'>User Name</th>
                            <th className='lg'>First Name</th>
                            <th className='lg'>Last Name</th>
                            <th className='lg'>Remove</th>
                        </tr>
                        </thead>
                        <tbody>
                        {cognitoGroup.groupMembers.length > 0 ? cognitoGroup.groupMembers.map((user, index) => {
                            return (<tr key={index}>
                                <td className='lg'>{index + 1}</td>
                                <td className='lg'>{user.Username}</td>
                                <td className='lg'>{user.Attributes?.find((att) => att.Name === 'given_name')?.Value}</td>
                                <td className='lg'>{user.Attributes?.find((att) => att.Name === 'family_name')?.Value}</td>
                                <td className='lg'>{<Button onClick={(e) => {
                                    removeUser(e, user)
                                }
                                }>Remove From Group</Button>}
                                </td>
                            </tr>)
                        }) : <tr>
                            <td className='lg'>0</td>
                            <td className='lg' colSpan={4}>There are no users in this group.</td>
                        </tr>}
                        </tbody>
                    </Table>
                </td>
            </tr>
            </tbody>
        </Table>
    )
}
