import {ChangeEvent, FC, MouseEventHandler, useState} from "react";
import {linkType} from "../types/types";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {useCognitoContext} from "../context/cognitoContext";

interface props {
    link: linkType;
    show: boolean;
    handleHide: () => void;
    handleClose: MouseEventHandler<HTMLButtonElement | HTMLAreaElement>;
    handleSave: (link: linkType) => void;
    handleDelete?: (link: linkType) => void;
}

const LinkForm: FC<props> = ({link, show, handleClose, handleHide, handleSave, handleDelete}) => {
    const {cognitoGroups} = useCognitoContext()
    // need a local state to keep the changes, instead of updating the parent item state.
    // modifying the parent state is possible, but if user cancels the edit form, parent state remains changed.
    // when user click "SAVE" submit the local state.
    const [state, setState] = useState({
        PK: link?.PK,
        SK: link?.SK,
        id: link?.id,
        link: link?.link,
        siteName: link?.siteName,
        description: link?.description,
        accessGroup: link?.accessGroup,
        displayPriority: link?.displayPriority
    });

    // track the change and keep it as a local state.
    const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLOptionElement | HTMLSelectElement>) => {
        const target = event.target;
        // all the submitted target.value are string, convert it to number for displayPriority attribute.
        const value = target.id === 'displayPriority' ? Number(target.value) : target.value

        setState({
            ...state,
            [target.id]: value
        });
    }

    return (
        <Modal show={show} onHide={handleHide}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Link</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>ID</Form.Label>
                        <Form.Control type="text" defaultValue={link.id} readOnly/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Name</Form.Label>
                        <Form.Control id="siteName" type="text" defaultValue={link.siteName}
                                      onChange={handleInputChange} placeholder="Enter Name"/>
                        <Form.Text className="text-muted">
                            Name of the site
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Description</Form.Label>
                        <Form.Control id="description" type="text" defaultValue={link.description}
                                      onChange={handleInputChange} placeholder="Enter Description"/>
                        <Form.Text className="text-muted">
                            Description of the site
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Url</Form.Label>
                        <Form.Control id="link" type="url" defaultValue={link.link} onChange={handleInputChange}
                                      placeholder="Enter URL"/>
                        <Form.Text className="text-muted">
                            URL link, e.g (https://makeabeeline.com)
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Display Order Priority</Form.Label>
                        <Form.Control id="displayPriority" min="1" max="5" step="1" type="range"
                                      defaultValue={link.displayPriority}
                                      onChange={handleInputChange}/>
                        <Form.Text className="text-muted">
                            To place the link on the top , please slide to the bar to the left.
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Access Group</Form.Label>
                        <Form.Select id="accessGroup" defaultValue={link.accessGroup}
                                     onChange={handleInputChange}>
                            {cognitoGroups.map((cg, index) => {
                                if (cg.group.GroupName === process.env.REACT_APP_COGNITO_SAML_GROUP) {
                                    return <option key={index} value={cg.group.GroupName}>anyone</option>
                                } else {
                                    return <option key={index} value={cg.group.GroupName}>{cg.group.GroupName}</option>
                                }
                            })}
                        </Form.Select>
                        <Form.Text className="text-muted">
                            Select the group who can access the link
                        </Form.Text>
                    </Form.Group>

                </Form>
                <Modal.Footer>
                    {handleDelete ? <Button variant="secondary" onClick={() => {
                        handleDelete(link)
                    }}>Delete</Button> : ''}
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => handleSave(state)}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal.Body>

        </Modal>
    )
}

export default LinkForm
